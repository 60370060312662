<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="0"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩网络</div>
        <div class="content2">用技术驱动内容 用数字重构传播</div>
      </div>
      <div></div>
    </div>
    <div class="page-content">
      <p class="title">掌玩简介</p>
      <p>
        「掌玩网络」成立于2014年，由互联网创业圈知名人物猫老大牵头成立，是一家综合型互联网新媒体矩阵平台，公司主营业务包含数字阅读、游戏发行、短剧、新媒体广告以及智能云服务等。2018年公司取得“国家高新技术企业”认定，至今公司全面拥抱市场挑战，并持续布局小说、游戏、短剧业务，继续努力在互联网浪潮中前行。
      </p>
      <p>
        公司总部位于人间天堂杭州市，目前团队人员近1000人，“掌握新动向，潜玩新媒体”，公司目前正在构建内容领域的未来生态发展蓝图，朝着优秀互联网内容平台的技术化、信息化发展目标砥砺前行。
      </p>
      <!-- <p>
        「掌玩网络」总部位于杭州，于2014年由国内新媒体圈知名人物——猫老大创始成立，通过内容、产品以及服务来吸引、汇聚各类垂直用户，
        主要业务包括在线阅读、社交电商、知识付费、风险投资等，是国内知名的新媒体内容矩阵平台，也是国家级高新技术企业。
      </p>
      <p>
        「掌玩网络」自主研发的“掌玩BI”智能操作系统，能够通过用户画像筛选受众，实现目标人群的精准触达，赋能业务实现用户精细化运营
        和服务，高效打通流量获取、运营、变现三大环节。
      </p>
      <p>
        「掌玩网络」用技术驱动内容生产，以数字化驱动重构传播，通过与腾讯、字节跳动、阅文以及浙江省各大高校等进行深度合作，致力于打
        造优秀的产业集聚、科技集聚、人才集聚的新媒体中心
      </p> -->
      <p><img src="/tu/tu111.png"></p>
    </div>
    <footer-bar />
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.push('/qiye')
    }, 800)
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(/bg/2.jpg);
  background-position: center center;
}
.page-content {
  max-width: 1360px;
  width: 60%;
  margin: 0 auto;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  padding: 100px 100px;
  .title {
    font-size: 34px;
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 36px;
  }
  img {
    width: 100%;
  }
}
</style>